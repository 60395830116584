export default new (class {
  constructor() {
    this.isMobileSafari =
      navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/);

    this.selectors = {
      body: 'body',
    };

    this.classes = {
      safari: 'is-safari',
      notSafari: 'not-safari',
    };

    window.addEventListener('DOMContentLoaded', () => {
      this.init();
    });
  }

  init() {
    this.getDOM();
    this.safari();
  }

  getDOM() {
    this.DOM = {
      body: document.querySelector(this.selectors.body),
    };
  }

  safari() {
    if (this.isMobileSafari) {
      this.DOM.body.classList.add(this.classes.safari);
    } else {
      this.DOM.body.classList.add(this.classes.notSafari);
    }
  }
})();
