export default new (class {
  constructor() {
    this.selectors = {
      wrapper: '.js-main-nav-wrapper',
      toggle: '.js-main-nav-toggle',
      expand: '.js-main-nav-expand',
      link: '.js-main-nav-link',
    };

    this.classes = {
      open: 'is-open',
      active: 'is-active',
      expanded: 'is-expanded',
      visible: 'is-visible',
    };

    window.addEventListener('DOMContentLoaded', () => {
      this.init();
    });
  }

  getDOM() {
    this.DOM = {
      wrapper: document.querySelector(this.selectors.wrapper),
      toggle: document.querySelector(this.selectors.toggle),
      expand: document.querySelectorAll(this.selectors.expand),
      link: document.querySelectorAll(this.selectors.link),
    };
  }

  init() {
    this.getDOM();
    this.bindToggle();
  }

  bindToggle() {
    this.DOM.toggle.addEventListener('click', () => {
      this.DOM.wrapper.classList.toggle(this.classes.open);
      this.DOM.toggle.classList.toggle(this.classes.active);
      this.toggleAria();
    });
    this.DOM.expand.forEach((item) => {
      const parent = item.parentElement;
      item.addEventListener('click', () => {
        parent.classList.toggle(this.classes.expanded);
      });
    });
    this.DOM.link.forEach((item) => {
      const parent = item.parentElement;
      item.addEventListener('click', (event) => {
        event.preventDefault();
        const dropdown = parent.querySelector('ul');
        if (parent && dropdown) {
          if (
            !parent.classList.contains(this.classes.expanded) &&
            !dropdown.classList.contains(this.classes.visible)
          ) {
            this.resetMenuDropdowns();
          }
        }
        if (parent) {
          parent.classList.toggle(this.classes.expanded);
        }
        if (dropdown) {
          dropdown.classList.toggle(this.classes.visible);
        }
      });
    });
  }

  resetMenuDropdowns() {
    this.DOM.link.forEach((item) => {
      const parent = item.parentElement;
      const dropdown = parent.querySelector('ul');
      if (parent) {
        parent.classList.remove(this.classes.expanded);
      }
      if (dropdown) {
        dropdown.classList.remove(this.classes.visible);
      }
    });
  }

  toggleAria() {
    if (this.DOM.wrapper.classList.contains(this.classes.open)) {
      this.DOM.toggle.setAttribute('aria-expanded', 'true');
    } else {
      this.DOM.toggle.setAttribute('aria-expanded', 'false');
    }
  }
})();
