export default new (class {
  constructor() {
    this.offset = null;
    this.selector = '.js-pawilon-tv-teaser';
    this.intValue = 20;
    this.marquee = null;

    window.addEventListener('DOMContentLoaded', () => {
      this.init();
    });
  }

  init() {
    this.getDOM();
    this.marqueeInit();
  }

  getDOM() {
    this.DOM = {
      marquee: document.querySelector(this.selector),
    };
  }

  getWidth() {
    if (this.DOM.marquee) {
      return this.DOM.marquee.parentElement.offsetWidth;
    }
    return 0;
  }

  getMaxOffset() {
    if (this.DOM.marquee) {
      return this.getWidth() / 2 + this.DOM.marquee.offsetWidth * 0.5;
    }
    return 0;
  }

  /* eslint-disable */
  getLeft(element) {
    return parseInt(
      window.getComputedStyle(element).getPropertyValue('left'),
      10,
    );
  }
  /* eslint-enable */

  marqueeInit() {
    if (!this.DOM.marquee) {
      return;
    }

    this.DOM.marquee.style.position = 'relative';
    this.DOM.marquee.style.left = 0;

    this.marquee = setInterval(() => {
      const newOffset = this.getLeft(this.DOM.marquee) - 1;
      this.DOM.marquee.style.left = `${newOffset}px`;
      if (newOffset < this.getMaxOffset() * -1) {
        this.DOM.marquee.style.left = `${this.getMaxOffset()}px`;
      }
      this.offset = this.DOM.marquee.style.left;
    }, this.intValue);
  }
})();

/**
 * TOOD:
 * 1. Add position relative
 * 2. Correct calculations
 */
