export default new (class {
  constructor() {
    this.selectors = {
      all: '.js-pawilon-tv-all',
      footer: '.js-footer',
      loader: '.js-pawilon-tv-loader',
      morePost: '.js-pawilon-tv-more-post',
      moreWrapper: '.js-pawilon-tv-more-posts',
      next: '.js-pawilon-tv-more-next',
      prev: '.js-pawilon-tv-more-prev',
      txtContent: '.js-pawilon-text',
      tvContent: '.js-pawilon-tv',
      tvOpen: '.js-pawilon-tv-open',
      tvClose: '.js-pawilon-tv-close',
      tvTeaser: '.js-pawilon-tv-teaser',
      videoTitle: '.js-pawilon-tv-title',
      videoPrev: '.js-pawilon-tv-prev',
      videoNext: '.js-pawilon-tv-next',
      videoVideo: '.js-pawilon-tv-video',
      videoPlay: '.js-pawilon-tv-play',
      videoDescToggle: '.js-pawilon-tv-desc',
      videoDescExpanded: '.js-pawilon-tv-desc-expanded',
      wrapper: '.js-pawilon-tv-wrapper',
    };

    this.controls = {
      firstLoad: true,
      currentPage: 1,
    };

    this.classes = {
      isCollapsed: 'is-content-collapsed',
      isExpanded: 'is-content-expanded',
      isVisible: 'is-visible',
      isHidden: 'is-hidden',
      video: 'c-pawilon-tv__more-post',
    };

    this.messages = {
      general: 'Error',
      loading: 'Pobieranie treści...',
    };

    this.settings = {
      restUrl: window.resturl,
    };

    window.addEventListener('DOMContentLoaded', () => {
      this.init();
    });
  }

  /* eslint-disable */

  getDOM() {
    this.DOM = {
      all: document.querySelector(this.selectors.all),
      loader: document.querySelector(this.selectors.loader),
      footer: document.querySelector(this.selectors.footer),
      morePosts: document.querySelectorAll(this.selectors.morePost),
      moreWrapper: document.querySelector(this.selectors.moreWrapper),
      next: document.querySelector(this.selectors.next),
      prev: document.querySelector(this.selectors.prev),
      txtContent: document.querySelectorAll(this.selectors.txtContent),
      tvContent: document.querySelector(this.selectors.tvContent),
      tvOpen: document.querySelector(this.selectors.tvOpen),
      tvClose: document.querySelectorAll(this.selectors.tvClose),
      tvTeaser: document.querySelector(this.selectors.tvTeaser),
      videoTitle: document.querySelector(this.selectors.videoTitle),
      videoPrev: document.querySelector(this.selectors.videoPrev),
      videoNext: document.querySelector(this.selectors.videoNext),
      videoVideo: document.querySelector(this.selectors.videoVideo),
      videoPlay: document.querySelector(this.selectors.videoPlay),
      videoDescToggle: document.querySelector(this.selectors.videoDescToggle),
      videoDescExpanded: document.querySelector(
        this.selectors.videoDescExpanded,
      ),
      wrapper: document.querySelector(this.selectors.wrapper),
    };
  }

  getJSON(url, errorMsg = messages.general) {
    return fetch(url).then((response) => {
      if (!response.ok) throw new Error(`${errorMsg} (${response.status})`);
      return response.json();
    });
  }

  init() {
    this.getDOM();
    this.firstLoad();
    this.bindEvents();
  }

  firstLoad() {
    this.fetchVideo();
  }

  bindEvents() {
    if (
      this.DOM.tvOpen &&
      this.DOM.tvClose &&
      this.DOM.tvContent &&
      this.DOM.txtContent
    ) {
      console.log('Initialize Pawilon TV');
      this.DOM.tvOpen.addEventListener('click', () => {
        this.DOM.txtContent.forEach((item) => {
          item.classList.add(this.classes.isCollapsed);
        });
        this.DOM.tvOpen.classList.add(this.classes.isCollapsed);
        this.DOM.tvContent.classList.add(this.classes.isExpanded);
        this.DOM.footer.classList.add(this.classes.isCollapsed);
        if (this.controls.firstLoad) {
          this.fetchVideo();
        }
        this.controls.firstLoad = false;
        this.DOM.wrapper.style.position = 'relative';
      });
      this.DOM.tvClose.forEach((close) => {
        close.addEventListener('click', () => {
          this.DOM.txtContent.forEach((item) => {
            item.classList.remove(this.classes.isCollapsed);
          });
          this.DOM.tvOpen.classList.remove(this.classes.isCollapsed);
          this.DOM.tvContent.classList.remove(this.classes.isExpanded);
          this.DOM.footer.classList.remove(this.classes.isCollapsed);
          this.DOM.wrapper.style.position = 'fixed';
        });
      });
      this.DOM.videoPrev.addEventListener('click', () => {
        const loadId = this.DOM.videoPrev.getAttribute('data-prev-id');
        this.loadingPlaceholder();
        this.fetchVideo(loadId);
      });
      this.DOM.videoNext.addEventListener('click', () => {
        const loadId = this.DOM.videoNext.getAttribute('data-next-id');
        this.loadingPlaceholder();
        this.fetchVideo(loadId);
      });
      this.DOM.videoDescToggle.addEventListener('click', () => {
        this.DOM.videoDescExpanded.classList.toggle(this.classes.isVisible);
      });

      if (this.DOM.morePosts) {
        this.DOM.morePosts.forEach((post) => {
          post.addEventListener('click', () => {
            const loadId = post.getAttribute('data-video-id');
            this.loadingPlaceholder();
            this.fetchVideo(loadId);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
        });

        this.DOM.next.addEventListener('click', () => {
          this.DOM.moreWrapper.style.opacity = 0;
          this.controls.currentPage =
            parseFloat(this.controls.currentPage) + parseFloat(1);
          this.listLoader(true);
          this.fetchVideos();
        });

        this.DOM.prev.addEventListener('click', () => {
          this.DOM.moreWrapper.style.opacity = 0;
          this.controls.currentPage =
            parseFloat(this.controls.currentPage) - parseFloat(1) < 1
              ? 1
              : parseFloat(this.controls.currentPage) - parseFloat(1);
          this.listLoader(true);
          this.fetchVideos();
        });
      }

      if (this.DOM.all) {
        this.DOM.all.addEventListener('click', () => {
          this.DOM.moreWrapper.style.opacity = 0;
          this.listLoader(true);
          this.fetchVideos(true);
          this.DOM.prev.remove();
          this.DOM.next.remove();
        });
      }
    }
  }

  fetchVideo(id = 0) {
    this.getJSON(
      `${this.settings.restUrl}pawilon/v1/tv/${id}`,
      this.messages.general,
    )
      .then((data) => {
        this.DOM.videoTitle.innerHTML = data[0][0].post_title;
        this.DOM.tvTeaser.innerHTML = data[0][0].post_title;
        this.DOM.videoVideo.innerHTML = data[0][1].embed;
        this.DOM.videoVideo.style.opacity = '1';
        this.DOM.videoDescExpanded.innerHTML = data[0][0].post_content;
        if (data[0][1].nearby.previous) {
          this.DOM.videoPrev.classList.remove(this.classes.isHidden);
          this.DOM.videoPrev.setAttribute(
            'data-prev-id',
            data[0][1].nearby.previous.ID,
          );
        } else {
          this.DOM.videoPrev.classList.add(this.classes.isHidden);
        }
        if (data[0][1].nearby.next) {
          this.DOM.videoNext.classList.remove(this.classes.isHidden);
          this.DOM.videoNext.setAttribute(
            'data-next-id',
            data[0][1].nearby.next.ID,
          );
        } else {
          this.DOM.videoNext.classList.add(this.classes.isHidden);
        }
        if (!data) throw new Error(this.messages.general);
      })
      .catch((err) => {
        console.error(this.messages.general);
      })
      .finally(() => {
        console.log('Successfully loaded video from Vimeo');
      });
  }

  fetchVideos(all = false) {
    this.getJSON(
      `${this.settings.restUrl}pawilon/v1/list/${
        all ? 0 : this.controls.currentPage
      }`,
      this.messages.general,
    )
      .then((data) => {
        if (data.length === 0) {
          this.controls.currentPage =
            parseFloat(this.controls.currentPage) - parseFloat(1) < 1
              ? 1
              : parseFloat(this.controls.currentPage) - parseFloat(1);
          console.log('No more videos');
        } else {
          this.DOM.moreWrapper.innerHTML = '';

          data.forEach((video) => {
            if (!this.DOM.moreWrapper) {
              return;
            }

            const thumbnail = video.post_thumbnail_url
              ? `<img src="${video.post_thumbnail_url}" class="c-pawilon-tv__thumbnail u-proportion-inner">`
              : '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 65"><path d="M1.5 2.076 54.005 32.5 1.5 62.924V2.076Z" fill="#000" stroke="#000" stroke-width="2"/></svg>';

            const newVideo = document.createElement('a');
            newVideo.setAttribute('href', `#${video.ID}`);
            newVideo.setAttribute('data-video-id', video.ID);
            newVideo.classList.add(this.classes.video);
            newVideo.innerHTML = `<figure class="u-proportion-outer" style="padding-top:66%;">${thumbnail}</figure><h3 class="c-pawilon-tv__more-title">${video.post_title}</h3>`;
            newVideo.addEventListener('click', () => {
              const loadId = newVideo.getAttribute('data-video-id');
              this.loadingPlaceholder();
              this.fetchVideo(loadId);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            });

            this.DOM.moreWrapper.appendChild(newVideo);
          });
        }
      })
      .catch((err) => {
        console.error(this.messages.general);
      })
      .finally(() => {
        this.listLoader();
        this.DOM.moreWrapper.style.opacity = 1;
      });
  }

  listLoader(show = false) {
    if (!show) {
      this.DOM.loader.classList.add(this.classes.isHidden);
    } else {
      this.DOM.loader.classList.remove(this.classes.isHidden);
    }
  }

  loadingPlaceholder() {
    this.DOM.videoVideo.style.opacity = '0';
    this.DOM.videoTitle.innerHTML = this.messages.loading;
    this.DOM.videoDescExpanded.innerHTML = this.messages.loading;
  }
})();
