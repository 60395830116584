export default new (class {
  constructor() {
    this.selectors = {
      topAnchor: '.js-scroll-top-anchor',
      topLink: '.js-scroll-top',
    };

    window.addEventListener('DOMContentLoaded', () => {
      this.init();
    });
  }

  getDOM() {
    this.DOM = {
      topAnchor: document.querySelector(this.selectors.topAnchor),
      topLink: document.querySelector(this.selectors.topLink),
    };
  }

  init() {
    this.getDOM();
    this.bindEvents();
  }

  bindEvents() {
    if (this.DOM.topLink) {
      this.DOM.topLink.addEventListener('click', (event) => {
        event.preventDefault();
        this.DOM.topAnchor.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      });
    }
  }
})();
