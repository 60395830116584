export default new (class {
  constructor() {
    this.selectors = {
      item: '.js-accordion',
      toggle: '.js-accordion-toggle',
      content: '.js-accordion-content',
      inner: '.js-accordion-inner',
    };

    this.classes = {
      active: 'active',
    };

    window.addEventListener('DOMContentLoaded', () => {
      this.init();
    });
  }

  init() {
    this.getDOM();
    this.bindClickEvent();
  }

  getDOM() {
    this.DOM = {
      toggle: document.querySelectorAll(this.selectors.toggle),
    };
  }

  bindClickEvent() {
    const toggles = Array.from(this.DOM.toggle);
    toggles.forEach((toggle) => {
      toggle.addEventListener('click', (event) => {
        this.toggle(event);
      });
    });
  }

  toggle(event) {
    const parent = event.currentTarget.closest(this.selectors.item);
    if (parent) {
      const content = parent.querySelector(this.selectors.content);
      if (!parent.classList.contains(this.classes.active)) {
        const inner = parent.querySelector(this.selectors.inner);
        if (inner) {
          content.style.height = `${inner.offsetHeight}px`;
        }
      } else {
        content.removeAttribute('style');
      }
    }
    parent.classList.toggle(this.classes.active);
  }
})();
