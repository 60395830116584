export default new (class {
  constructor() {
    this.classes = {
      collapse: 'is-collapsed',
    };

    this.selectors = {
      preheader: '.js-preheader',
      arrow: '.js-preheader-arrow',
    };

    this.toggles = {
      isCollapsed: false,
      autoCollapse: false,
      autoCollapseOnScroll: true,
    };

    this.values = {
      collapseThreshold: 1,
    };

    this.isTouchDevice = window.matchMedia('(pointer: coarse)').matches;

    window.addEventListener('DOMContentLoaded', () => {
      this.init();
    });
  }

  init() {
    this.getDOM();
    this.bindEvents();
    this.initialState();
    if (this.toggles.autoCollapse) {
      setTimeout(() => {
        this.collapsePreheader();
      }, 3000);
    }
  }

  getDOM() {
    this.DOM = {
      arrow: document.querySelector(this.selectors.arrow),
      preheader: document.querySelector(this.selectors.preheader),
    };
  }

  bindEvents() {
    if (!this.DOM.arrow || !this.DOM.preheader) return;
    if (this.toggles.autoCollapseOnScroll) {
      window.addEventListener('scroll', () => {
        this.collapsePreheaderOnScroll();
      });
      /* eslint-disable */
      if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
      } else {
        window.addEventListener('beforeunload', () => {
          window.scrollTo(0, 0);
        });
      }
      /* eslint-enable */
    }
    this.DOM.arrow.addEventListener('click', () => {
      this.collapsePreheader();
    });
    this.DOM.preheader.addEventListener('click', () => {
      this.collapsePreheader();
    });
  }

  initialState() {
    if (this.toggles.autoCollapseOnScroll) {
      this.collapsePreheaderOnScroll();
    }
  }

  collapsePreheader() {
    if (!this.DOM.preheader) return;
    if (!this.DOM.isCollapsed) {
      this.toggles.isCollapsed = true;
      this.DOM.preheader.classList.add(this.classes.collapse);
    }
  }

  collapsePreheaderOnScroll() {
    if (
      window.scrollY > this.values.collapseThreshold &&
      !this.toggles.isCollapsed
    ) {
      this.collapsePreheader();
    }
  }
})();
