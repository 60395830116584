export default new (class {
  constructor() {
    this.classes = {
      hide: 'is-hidden',
    };

    this.selectors = {
      content: '.js-content',
      footer: '.js-footer',
      letter: '.js-letter',
      logo: '.js-logo',
      preheader: '.js-preheader',
      subheader: '.js-subheader',
    };

    this.toggles = {
      isHidden: false,
    };

    this.values = {
      logoHideThreshold: 40,
      logoBreakpoint: 1390,
      logoPaddingThreshold: 1390,
      logoPaddingTopFixed: '34rem',
      logoPaddingTopRelative: '22vw',
    };

    this.isTouchDevice = window.matchMedia('(pointer: coarse)').matches;

    window.addEventListener('DOMContentLoaded', () => {
      this.init();
    });
  }

  init() {
    this.getDOM();
    this.bindEvents();
    this.initialState();
  }

  getDOM() {
    this.DOM = {
      content: document.querySelector(this.selectors.content),
      footer: document.querySelector(this.selectors.footer),
      letter: document.querySelector(this.selectors.letter),
      logo: document.querySelector(this.selectors.logo),
      preheader: document.querySelector(this.selectors.preheader),
      subheader: document.querySelector(this.selectors.subheader),
    };
  }

  bindEvents() {
    window.addEventListener('scroll', () => {
      if (!this.mobileLogo()) {
        this.hideLogo();
      }
    });
  }

  initialState() {
    this.hideLogo();
  }

  calculateOffset() {
    const preheaderHeight =
      this.DOM.preheader !== null ? this.DOM.preheader.offsetHeight : 0;
    const windowScroll = window.scrollY;
    return windowScroll - preheaderHeight;
  }

  hideLogo() {
    if (this.calculateOffset() > this.values.logoHideThreshold) {
      if (!this.toggles.isHidden) {
        this.toggles.isHidden = true;
        this.DOM.subheader.classList.add(this.classes.hide);
        this.DOM.logo.classList.add(this.classes.hide);
      }
    } else if (this.toggles.isHidden) {
      this.toggles.isHidden = false;
      this.DOM.subheader.classList.remove(this.classes.hide);
      this.DOM.logo.classList.remove(this.classes.hide);
    }
  }

  mobileLogo() {
    const windowWidth = window.innerWidth;
    if (windowWidth < this.values.logoBreakpoint) {
      return true;
    }
    return false;
  }
})();
